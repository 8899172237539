@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alata&family=Inter:wght@200;400;600;700;800;900&family=Lato:wght@300;400&display=swap');

body {
  background-color: #f5f5f5;
}

.pointer-cursor {
  cursor: pointer;
}

/* NAVBAR STYLING */
.navbar-text {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #333;
  font-size: small;
}
.navbar-text-lite {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #f5f5f5;
  font-size: small;
}

.navbar-text a.hovered {
  color: #238481;
  font-weight: 500;
}

.reach-out {
  font-family: 'Lato', sans-serif;
  color: #238480;
  font-weight: 400;
  letter-spacing: 3px;
  text-decoration: none;
  display: inline-block; /* Make the link inline-block to control its width */
  padding-bottom: 10px; /* Adjust the padding as needed */
  line-height: 1; /* Set the line-height to 1 to match the height of the text */
  position: relative; /* Add relative positioning to create the animation */
}

/* Add hover styles to show the border-bottom with a panning animation */
.reach-out::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #238480;
  transition: width 0.5s ease, margin 0.5s ease; /* Add transitions for width and margin */
}

.reach-out:hover::after {
  width: 100%; /* Expand the width to create a panning animation */
  margin-left: 0; /* Reset the margin on hover to avoid extra padding */
}

.reach-out a.hovered {
  font-weight: 500;
  color: #238480;
}

.mobile-reach-out {
  font-family: 'Lato', sans-serif;
  color: #238480;
  font-weight: 400;
  font-size: medium;
  letter-spacing: 3px;
  text-decoration: none;
  border-bottom: 2px solid #238480;
  padding-bottom: 10px;
  display: inline-block; 
  max-width: 100%; 
}
.mobile-reach-out-lite {
  font-family: 'Lato', sans-serif;
  color: #f5f5f5;
  font-weight: 400;
  font-size: medium;
  letter-spacing: 3px;
  text-decoration: none;
  border-bottom: 2px solid #F5f5f5;
  padding-bottom: 10px;
  display: inline-block; 
  max-width: 100%; 
}

/* ETHOS STYLING */
.ethos-heading {
  font-family: 'Lato', sans-serif; 
  font-weight: 300;
}

.more-about {
  font-family: 'Lato', sans-serif;
  color: #238480;
  font-weight: 400;
  letter-spacing: 3px;
  text-decoration: none;
  display: inline-block; /* Make the link inline-block to control its width */
  padding-bottom: 10px; /* Adjust the padding as needed */
  line-height: 1; /* Set the line-height to 1 to match the height of the text */
  position: relative; /* Add relative positioning to create the animation */
}

/* Add hover styles to show the border-bottom with a panning animation */
.more-about::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #238480;
  transition: width 0.5s ease, margin 0.5s ease; /* Add transitions for width and margin */
  margin-left: 10px; /* Adjust the margin to control the padding between text and animation */
}

.more-about:hover::after {
  width: 100%; /* Expand the width to create a panning animation */
  margin-left: 0; /* Reset the margin on hover to avoid extra padding */
}

.more-about a.hovered {
  font-weight: 500;
  color: #238480;
}

.section-heading {
  font-family: 'Alata', sans-serif; 
  font-weight: bold;
  color: #238480;
}

.section-line {
  flex-grow: 1;
  height: 5px; /* Adjust the height of the line as needed */
  background-color: #238480; /* Replace with your desired color */
  margin-right: 10px; /* Adjust the margin between the line and text */
}

/* Hero STYLING */

.hero-heading {
  font-family: 'Inter', sans-serif; 
  font-weight: 800;
  letter-spacing: -.05em;
  line-height: 1;
}

.hero-text {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: -.02em;
  line-height: 2em!important;
}

.gradient-text {
  background-image: linear-gradient(to right, #182826, #32bdb8); /* Adjust gradient colors as needed */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: background 1s ease; /* Adjust the animation duration as needed */
}

.black-text {
  color: #182826;
}

.parallax-image {
  background-image: url(./utils/skyline.svg);
  background-position: bottom;
  background-size: cover;
}

.skyline-image {
  background-image: url(./utils/skyline.svg);
  background-position: bottom;
  background-size: cover;
}

.button-text {
  font-family: 'Lato', sans-serif; 
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.0625rem;
}

.footer-heading {
  font-family: 'Lato', sans-serif; 
  font-weight: 400;
}
.footer-text {
  font-family: 'Inter', sans-serif;
  font-weight: 200;
  color: #94A3B8;
  font-size: small;
}

.footer-text a.hovered {
  color: #238480;
}

.process-number {
  font-family: 'Inter', sans-serif; 
  font-weight: 800;
  color: #238481;
  opacity: 0.5;
}

.quote-text {
  font-family: 'Abril Fatface', cursive;
  font-weight: 400;
  font-style: italic;
}

.about-icon {
  object-fit: scale-down;
  width: 10vw;
}

@media only screen and (max-width: 600px){
  .about-icon {
    width: 30vw;
  }
}




